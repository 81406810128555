<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-tabs
                        v-model="operationsDataOverviewCurrentTab"
                        color="success"
                        background-color="grey lighten-2"
                        slider-color="appic-green"
                    >
                        <v-tab key="countries" @click="changeExportParams('countries')">{{ $t('message.countries') }}</v-tab>
                        <v-tab key="ports" @click="changeExportParams('ports')">{{ $t('message.shippingPorts') }}</v-tab>
                        <v-tab key="incoterms">{{ $t('message.incoterms') }}</v-tab>
                        <v-tab key="agents" @click="changeExportParams('agents')">{{ $t('message.shippingAgents') }}</v-tab>
                        <v-tab key="couriers">{{ $t('message.couriers') }}</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="operationsDataOverviewCurrentTab">
                        <v-tab-item key="countries-list">
                            <v-card flat>
                                <CountriesListing
                                    :add-dialog="openAddCountryDialog"
                                    :key="countriesListKey"
                                    @add-dialog-closed="addCountryDialogClosed"
                                    @reload="reloadCountriesList"
                                />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="ports-list">
                            <v-card flat>
                                <ShippingPortsListing
                                    :add-dialog="openAddPortDialog"
                                    :key="shippingPortsListKey"
                                    @add-dialog-closed="addPortDialogClosed"
                                    @reload="reloadShippingPortsList"
                                />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="incoterms-list">
                            <v-card flat>
                                <IncoTermsList
                                    :add-dialog="openAddIncoTermDialog"
                                    :key="incoTermsListKey"
                                    @add-dialog-closed="addIncoTermDialogClosed"
                                    @reload="reloadIncoTermsList"
                                />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="agents-list">
                            <v-card flat>
                                <AgentsListing
                                    :add-dialog="openAddAgentDialog"
                                    :key="agentsListKey"
                                    @add-dialog-closed="addAgentDialogClosed"
                                    @reload="reloadAgentsList"
                                ></AgentsListing>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="couriers-list">
                            <v-card flat>
                                <CouriersListing
                                    :key="couriersListKey"
                                    @reload="reloadCouriersList"
                                />
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";

const AgentsListing = () => import("Components/Appic/AgentsListing");
// const BuyerRemarksList = () => import( "Components/Appic/BuyerRemarksList.vue");
const CountriesListing = () => import("Components/Appic/CountriesListing");
const ExportTable = () => import("Components/Appic/ExportTable");
// const SalesColleaguesListing = () => import("Components/Appic/SalesColleaguesListing");
const ShippingPortsListing = () => import("Components/Appic/ShippingPortsListing");
// const SiDocumentManager = () => import("Components/Appic/SiDocumentManager");
// const ShippingDocumentAddressesListing = () => import("Components/Appic/ShippingDocumentAddressesListing");
// const EtdAdjustmentsListing = () => import("Components/Appic/EtdAdjustmentsListing");
// const StandardShippingDocumentsListing = () => import("Components/Appic/StandardShippingDocumentsListing");
const IncoTermsList = () => import("Components/Appic/IncoTermsList");
// const DocumentFootersList = () => import("Components/Appic/DocumentFootersList");
const CouriersListing = () => import("Components/Appic/CouriersListing.vue");

export default {
    name: "OperationsDataOverview",
    components: {
        // BuyerRemarksList,
        CouriersListing,
        AgentsListing,
        CountriesListing,
        // DocumentFootersList,
        // EtdAdjustmentsListing,
        ExportTable,
        IncoTermsList,
        // SalesColleaguesListing,
        // ShippingDocumentAddressesListing,
        ShippingPortsListing,
        // SiDocumentManager,
        // StandardShippingDocumentsListing
    },
    title: '',
    data() {
        return {
            agentsListKey: 0,
            buyerRemarksListKey: 0,
            documentAddressesListKey: 0,
            documentFootersListKey: 0,
            etdAdjustmentsListKey: 0,
            incoTermsListKey: 0,
            loader: false,
            active: null,
            colsToDelete: {
                start_col: 10,
                ncols: 1
            },
            countriesListKey: 0,
            couriersListKey: 0,
            fileName: 'agents',
            hasAddFunction: true,
            document: 'agents',
            tableId: 'agentsTable',
            openAddAgentDialog: false,
            openAddCountryDialog: false,
            openAddDocumentAddressDialog: false,
            openAddDocumentFooterDialog: false,
            openAddEtdAdjustmentDialog: false,
            openAddIncoTermDialog: false,
            openAddPortDialog: false,
            openAddSalesColleagueDialog: false,
            openAddStandardShippingDocumentDialog: false,
            salesColleaguesListKey: 0,
            shippingPortsListKey: 0,
            standardShippingDocumentsListKey: 0
        }
    },
    computed: {
        ...mapFields('runtime',{
            operationsDataOverviewCurrentTab: 'operationsDataOverviewCurrentTab'
        })
    },
    methods: {
        addAgentDialogClosed () {
            this.openAddAgentDialog = false
        },
        addCountryDialogClosed () {
            this.openAddCountryDialog = false
        },
        addDocumentAddressDialogClosed () {
            this.openAddDocumentAddressDialog = false
        },
        addDocumentFooterDialogClosed () {
            this.openAddDocumentFooterDialog = false
        },
        addEtdAdjustmentDialogClosed () {
            this.openAddEtdAdjustmentDialog = false
        },
        addIncoTermDialogClosed () {
            this.openAddIncoTermDialog = false
        },
        addPortDialogClosed () {
            this.openAddPortDialog = false
        },
        addSalesColleagueDialogClosed () {
            this.openAddSalesColleagueDialog = false
        },
        addStandardShippingDocumentDialogClosed () {
            this.openAddStandardShippingDocumentDialog = false
        },
        changeExportParams(itemType){
            switch (itemType) {
                case 'agents':
                    this.colsToDelete = {start_col: 0, ncols: 1}
                    this.fileName = 'agents'
                    this.hasAddFunction = true
                    this.document = 'agents'
                    this.tableId = 'agentsTable'
                    break
                case 'ports':
                    this.colsToDelete = {start_col: 0, ncols: 1}
                    this.fileName = 'ports'
                    this.hasAddFunction = true
                    this.document = 'ports'
                    this.tableId = 'portsTable'
                    break
                case 'countries':
                    this.colsToDelete = {start_col: 0, ncols: 1}
                    this.fileName = 'countries'
                    this.hasAddFunction = true
                    this.document = 'countries'
                    this.tableId = 'countriesTable'
                    break
                // case 'sales':
                //     this.colsToDelete = {start_col: 0, ncols: 1}
                //     this.fileName = 'salesColleagues'
                //     this.hasAddFunction = true
                //     this.document = 'salesColleagues'
                //     this.tableId = 'salesColleaguesTable'
                //     break
                // case 'sidocaddresses':
                //     this.colsToDelete = {start_col: 0, ncols: 1}
                //     this.fileName = 'documentAddresses'
                //     this.hasAddFunction = true
                //     this.document = 'documentAddresses'
                //     this.tableId = 'documentAddressesTable'
                //     break
                // case 'stddocuments':
                //     this.colsToDelete = {start_col: 0, ncols: 1}
                //     this.fileName = 'standardShippingDocuments'
                //     this.hasAddFunction = true
                //     this.document = 'standardShippingDocuments'
                //     this.tableId = 'standardShippingDocumentsTable'
                //     break
            }
        },
        newItem (itemType) {
            switch(itemType){
                case 'agents':
                    this.openAddAgentDialog = true
                    break
                case 'countries':
                    this.openAddCountryDialog = true
                    break
                case 'ports':
                    this.openAddPortDialog = true
                    break
                case 'salesColleagues':
                    this.openAddSalesColleagueDialog = true
                    break
                case 'sidocaddresses':
                    this.openAddDocumentAddressDialog = true
                    break
                case 'stddocuments':
                    this.openAddStandardShippingDocumentDialog = true
                    break
            }
        },
        reloadAgentsList () {
            this.agentsListKey = Math.floor(Math.random() * 100)
        },
        reloadBuyerRemarksList() {
            this.buyerRemarksListKey = Math.floor(Math.random() * 100)
        },
        reloadCountriesList () {
            this.countriesListKey = Math.floor(Math.random() * 100)
        },
        reloadCouriersList() {
            this.couriersListKey = Math.floor(Math.random() * 100)
        },
        reloadDocumentAddressesList () {
            this.documentAddressesListKey = Math.floor(Math.random() * 100)
        },
        reloadDocumentFootersList () {
            this.documentFootersListKey = Math.floor(Math.random() * 100)
        },
        reloadEtdAdjustmentsList () {
            this.etdAdjustmentsListKey = Math.floor(Math.random() * 100)
        },
        reloadIncoTermsList () {
            this.incoTermsListKey = Math.floor(Math.random() * 100)
        },
        reloadSalesColleaguesList () {
            this.salesColleaguesListKey = Math.floor(Math.random() * 100)
        },
        reloadShippingPortsList () {
            this.shippingPortsListKey = Math.floor(Math.random() * 100)
        },
        reloadStandardShippingDocumentsList () {
            this.standardShippingDocumentsListKey = Math.floor(Math.random() * 100)
        }
    },
    mounted() {
        switch(this.operationsDataOverviewCurrentTab){
            case 0:
                this.changeExportParams('agents')
                break
            case 1:
                this.changeExportParams('ports')
                break
            case 2:
                this.changeExportParams('countries')
                break
            case 3:
                this.changeExportParams('sales')
                break
            case 5:
                this.changeExportParams('sidocaddresses')
                break
        }
    }
}
</script>

<style scoped>
.v-tab {
    text-transform: none!important;
}
</style>